var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'OrganizationHome',
            }}},[_vm._v(" 組織資料設定 ")]),_c('b-breadcrumb-item',{attrs:{"to":{
              name: _vm.isByMerchant
                ? 'OrganizationVariableListByMerchant'
                : 'OrganizationVariableList',
            }}},[_vm._v(" 組織參數管理 ")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(" 組織參數設定 ")])],1)],1)])]),_c('b-card',[_c('div',{staticClass:"row mb-2 align-items-end"},[_c('div',{staticClass:"col-8 d-flex items-center"},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.organization.name)+" 組織設定")])])]),_c('b-overlay',{attrs:{"show":_vm.isLoading,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"primary"}},[_c('b-form-group',{attrs:{"label-cols":"12","label-cols-lg":"2","label-size":"sm","label":"組織參數名稱"}},[_c('b-form-input',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"type":"text","readonly":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('b-form-group',{attrs:{"label-cols":"12","label-cols-lg":"2","label-size":"sm","label":"組織參數代號"}},[_c('b-form-input',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"type":"text","readonly":""},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1),(_vm.forms.length === 1)?_c('b-form-group',{staticClass:"group pt-4",attrs:{"label-cols":"12","label-cols-lg":"2","label-size":"sm","label":_vm.forms[0] && _vm.forms[0].name}},[(_vm.forms[0].type == 'boolean')?_c('b-form-checkbox',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"id":_vm.forms[0].key,"name":_vm.forms[0].key,"value":true,"unchecked-value":false},model:{value:(_vm.form[_vm.forms[0].key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.forms[0].key, $$v)},expression:"form[forms[0].key]"}}):(_vm.forms[0].type == 'color')?_c('color-picker',{attrs:{"id":_vm.forms[0].key},model:{value:(_vm.form[_vm.forms[0].key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.forms[0].key, $$v)},expression:"form[forms[0].key]"}}):(
            _vm.forms[0].type == 'array' &&
            _vm.forms[0].config &&
            _vm.forms[0].config['ui-data'] &&
            _vm.forms[0].config['ui-data']['type'] == 'array-checkbox'
          )?_c('b-form-checkbox-group',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"id":_vm.forms[0].key,"options":_vm.forms[0].config['ui-data']['options'],"name":_vm.forms[0].key},model:{value:(_vm.form[_vm.forms[0].key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.forms[0].key, $$v)},expression:"form[forms[0].key]"}}):(_vm.forms[0].type == 'json' || _vm.forms[0].type == 'array')?_c('editor',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"id":_vm.forms[0].key,"width":"100%","height":"200px","lang":"json","theme":"chrome"},on:{"init":_vm.editorInit},model:{value:(_vm.form[_vm.forms[0].key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.forms[0].key, $$v)},expression:"form[forms[0].key]"}}):(_vm.forms[0].type == 'html')?_c('editor',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"id":_vm.forms[0].key,"width":"100%","height":"200px","lang":"html","theme":"chrome"},on:{"init":_vm.editorInit},model:{value:(_vm.form[_vm.forms[0].key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.forms[0].key, $$v)},expression:"form[forms[0].key]"}}):(_vm.forms[0].type == 'number')?_c('b-form-input',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"id":_vm.forms[0].key,"type":"number"},model:{value:(_vm.form[_vm.forms[0].key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.forms[0].key, $$v)},expression:"form[forms[0].key]"}}):(_vm.forms[0].type == 'file')?_c('button',{staticClass:"btn btn-link px-2 text-dark align-self-start upload-button"},[_c('b-form-file',{on:{"change":function($event){return _vm.upload($event, _vm.forms[0].key)}}}),(!!_vm.form[_vm.forms[0].key])?_c('a',{staticClass:"btn-sm btn-outline-danger",on:{"click":function($event){_vm.form[_vm.forms[0].key] = null}}},[_c('i',{staticClass:"fa fa-trash-o"})]):_vm._e(),_c('a',{attrs:{"href":_vm.form[_vm.forms[0].key],"target":"_blank"}},[_c('small',[_vm._v(" 目前路徑："+_vm._s(_vm.form[_vm.forms[0].key] || "未上傳")+" ")])])],1):(
            _vm.forms[0].type == 'string' &&
            _vm.forms[0].config &&
            _vm.forms[0].config['ui-data'] &&
            _vm.forms[0].config['ui-data']['type'] == 'select'
          )?_c('b-form-select',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"options":(_vm.forms[0].config['ui-data'] &&
              _vm.forms[0].config['ui-data']['options']) ||
            {},"id":_vm.form.key,"state":_vm.v$.form[_vm.forms[0].key] && _vm.v$.form[_vm.forms[0].key].$error
              ? false
              : null},model:{value:(_vm.form[_vm.forms[0].key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.forms[0].key, $$v)},expression:"form[forms[0].key]"}}):(
            _vm.forms[0].type == 'string' &&
            _vm.forms[0].config &&
            _vm.forms[0].config['ui-data'] &&
            _vm.forms[0].config['ui-data']['type'] == 'radio'
          )?_c('b-form-radio-group',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"options":(_vm.forms[0].config['ui-data'] &&
              _vm.forms[0].config['ui-data']['options']) ||
            {},"id":_vm.form.key,"state":_vm.v$.form[_vm.forms[0].key] && _vm.v$.form[_vm.forms[0].key].$error
              ? false
              : null},model:{value:(_vm.form[_vm.forms[0].key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.forms[0].key, $$v)},expression:"form[forms[0].key]"}}):_c('b-form-input',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"id":_vm.form.key,"type":"text","state":_vm.v$.form[_vm.forms[0].key] && _vm.v$.form[_vm.forms[0].key].$error
              ? false
              : null},model:{value:(_vm.form[_vm.forms[0].key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.forms[0].key, $$v)},expression:"form[forms[0].key]"}}),_vm._l((_vm.v$.form[_vm.forms[0].key].$errors),function(error,i){return _c('b-form-invalid-feedback',{key:i,attrs:{"state":_vm.v$.form[_vm.forms[0].key] && _vm.v$.form[_vm.forms[0].key].$error
              ? false
              : null}},[_vm._v(" "+_vm._s(error.$message || "欄位為必填")+" ")])})],2):_vm._l((_vm.formGroups),function(forms,i){return _c('b-form-group',{key:i,staticClass:"group pt-4",attrs:{"label-cols":"12","label-cols-lg":"2","label-size":"m","label":forms[0] && forms[0].category,"label-class":"font-weight-bold"}},[_c('section',{staticClass:"row mb-8"},[_c('div',{staticClass:"col-12 col-xl-8"},_vm._l((forms),function(f){return _c('b-form-group',{key:f.id,attrs:{"label-cols":"12","label-cols-lg":"2","label-size":"sm","description":f.description || '',"label":f.name || '',"label-for":f.key || ''}},[(f.type == 'boolean')?_c('b-form-checkbox',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"id":f.key,"name":f.key,"value":true,"unchecked-value":false},model:{value:(_vm.form[f.key]),callback:function ($$v) {_vm.$set(_vm.form, f.key, $$v)},expression:"form[f.key]"}}):(f.type == 'color')?_c('color-picker',{attrs:{"id":f.key},model:{value:(_vm.form[f.key]),callback:function ($$v) {_vm.$set(_vm.form, f.key, $$v)},expression:"form[f.key]"}}):(
                  f.type == 'array' &&
                  f.config &&
                  f.config['ui-data'] &&
                  f.config['ui-data']['type'] == 'array-checkbox'
                )?_c('b-form-checkbox-group',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"id":f.key,"options":f.config['ui-data']['options'],"name":f.key},model:{value:(_vm.form[f.key]),callback:function ($$v) {_vm.$set(_vm.form, f.key, $$v)},expression:"form[f.key]"}}):(f.type == 'json' || f.type == 'array')?_c('editor',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"id":f.key,"width":"100%","height":"200px","lang":"json","theme":"chrome"},on:{"init":_vm.editorInit},model:{value:(_vm.form[f.key]),callback:function ($$v) {_vm.$set(_vm.form, f.key, $$v)},expression:"form[f.key]"}}):(f.type == 'html')?_c('editor',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"id":f.key,"width":"100%","height":"200px","lang":"html","theme":"chrome"},on:{"init":_vm.editorInit},model:{value:(_vm.form[f.key]),callback:function ($$v) {_vm.$set(_vm.form, f.key, $$v)},expression:"form[f.key]"}}):(f.type == 'number')?_c('b-form-input',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"id":f.key,"type":"number"},model:{value:(_vm.form[f.key]),callback:function ($$v) {_vm.$set(_vm.form, f.key, $$v)},expression:"form[f.key]"}}):(f.type == 'file')?_c('button',{staticClass:"\n                  btn btn-link\n                  px-2\n                  text-dark\n                  align-self-start\n                  upload-button\n                "},[_c('b-form-file',{on:{"change":function($event){return _vm.upload($event, f.key)}}}),(!!_vm.form[f.key])?_c('a',{staticClass:"btn-sm btn-outline-danger",on:{"click":function($event){_vm.form[f.key] = null}}},[_c('i',{staticClass:"fa fa-trash-o"})]):_vm._e(),_c('a',{attrs:{"href":_vm.form[f.key],"target":"_blank"}},[_c('small',[_vm._v(" 目前路徑："+_vm._s(_vm.form[f.key] || "未上傳")+" ")])])],1):(
                  f.type == 'string' &&
                  f.config &&
                  f.config['ui-data'] &&
                  f.config['ui-data']['type'] == 'select'
                )?_c('b-form-select',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"options":(f.config['ui-data'] && f.config['ui-data']['options']) ||
                  {},"id":_vm.form.key,"state":_vm.v$.form[f.key] && _vm.v$.form[f.key].$error ? false : null},model:{value:(_vm.form[f.key]),callback:function ($$v) {_vm.$set(_vm.form, f.key, $$v)},expression:"form[f.key]"}}):(
                  f.type == 'string' &&
                  f.config &&
                  f.config['ui-data'] &&
                  f.config['ui-data']['type'] == 'radio'
                )?_c('b-form-radio-group',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"options":(f.config['ui-data'] && f.config['ui-data']['options']) ||
                  {},"id":_vm.form.key,"state":_vm.v$.form[f.key] && _vm.v$.form[f.key].$error ? false : null},model:{value:(_vm.form[f.key]),callback:function ($$v) {_vm.$set(_vm.form, f.key, $$v)},expression:"form[f.key]"}}):_c('b-form-input',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"id":_vm.form.key,"type":"text","state":_vm.v$.form[f.key] && _vm.v$.form[f.key].$error ? false : null},model:{value:(_vm.form[f.key]),callback:function ($$v) {_vm.$set(_vm.form, f.key, $$v)},expression:"form[f.key]"}}),_vm._l((_vm.v$.form[f.key].$errors),function(error,i){return _c('b-form-invalid-feedback',{key:i,attrs:{"state":_vm.v$.form[f.key] && _vm.v$.form[f.key].$error ? false : null}},[_vm._v(" "+_vm._s(error.$message || "欄位為必填")+" ")])})],2)}),1)])])})],2),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"mr-4",attrs:{"variant":"outline-danger"},on:{"click":_vm.handleCancel}},[_vm._v("取消")]),_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.isSubmmiting,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"primary"}},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"success","disabled":_vm.isLoading},on:{"click":function($event){return _vm.handleSubmit(false)}}},[_vm._v(" 儲存 ")]),_c('b-button',{attrs:{"variant":"success","disabled":_vm.isLoading},on:{"click":_vm.handleSubmit}},[_vm._v(" 儲存後關閉 ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }